'use strict';

/**
 * AlertasENotificacoes
 */

; (function (angular) {

    angular
        .module('appAlertasENotificacoes', [
            'vs-repeat',
            'gc-find',
            'gc-ajax',
            'gc-transform',
            'gc-form',
            'gc-utils',
            'ngFileUpload',
            'ui.utils.masks',
            'ngCookies'
        ]);

    angular
        .module('appAlertasENotificacoes')
        .value('gScope', {})
        .controller('CrtAlertasENotificacoes', CrtAlertasENotificacoes);

    CrtAlertasENotificacoes.$inject = [
        '$scope',
        '$ajax',
        '$timeout',
        '$rootScope',
        '$q',
        '$compile',
        'gScope',
        '$sce',
        '$cookies',
    ];

    var elm = document.getElementById('idAlertasENotificacoes');
    angular.bootstrap(elm, ['appAlertasENotificacoes']);

    function CrtAlertasENotificacoes($scope, $ajax, $timeout, $rootScope, $q, $compile, gScope, $sce, $cookies) {

        var vm              = this;
        const cookieExpTime = 365242    // 1000 days

        $scope.trustAsHtml = function(string) {
            var html = htmlDecode(string);
            return $sce.trustAsHtml(html);
        };

        function htmlDecode(str) {
            
            str = str.replace(/&QUOT;/g,    '&quot;'   );
            str = str.replace(/&NBSP;/g,    '&nbsp;'   );
            str = str.replace(/&AACUTE;/g,  '&Aacute;' );
            str = str.replace(/&ACIRC;/g,   '&Acirc;'  );
            str = str.replace(/&AGRAVE;/g,  '&Agrave;' );
            str = str.replace(/&ARING;/g,   '&Aring;'  );
            str = str.replace(/&ATILDE;/g,  '&Atilde;' );
            str = str.replace(/&AUML;/g,    '&Auml;'   );
            str = str.replace(/&AELIG;/g,   '&AElig;'  );
            str = str.replace(/&EACUTE;/g,  '&Eacute;' );
            str = str.replace(/&ECIRC;/g,   '&Ecirc;'  );
            str = str.replace(/&EGRAVE;/g,  '&Egrave;' );
            str = str.replace(/&EUML;/g,    '&Euml;'   );
            str = str.replace(/&ETH;/g,     '&ETH;'    );
            str = str.replace(/&IACUTE;/g,  '&Iacute;' );
            str = str.replace(/&ICIRC;/g,   '&Icirc;'  );
            str = str.replace(/&IGRAVE;/g,  '&Igrave;' );
            str = str.replace(/&IUML;/g,    '&Iuml;'   );
            str = str.replace(/&OACUTE;/g,  '&Oacute;' );
            str = str.replace(/&OCIRC;/g,   '&Ocirc;'  );
            str = str.replace(/&OGRAVE;/g,  '&Ograve;' );
            str = str.replace(/&OSLASH;/g,  '&Oslash;' );
            str = str.replace(/&OTILDE;/g,  '&Otilde;' );
            str = str.replace(/&OUML;/g,    '&Ouml;'   );
            str = str.replace(/&UACUTE;/g,  '&Uacute;' );
            str = str.replace(/&UCIRC;/g,   '&Ucirc;'  );
            str = str.replace(/&UGRAVE;/g,  '&Ugrave;' );
            str = str.replace(/&UUML;/g,    '&Uuml;'   );
            str = str.replace(/&CCEDIL;/g,  '&Ccedil;' );

            return str;
        }

        vm.key_object_scop    = -1;
        vm.div_elemento_id    = 'objAlertasENotificacoes';
        vm._elemento          = {};

        vm.LISTA_NOTIFICACOES = [];
        vm.LISTA_CHAMADOS     = [];
        vm.NUM_NOTIFICACOES   = 0;
        vm.NOTA_AVALIACAO     = {};

        vm.countNotifications = function(counter){

            var numNotifEl          = $('.notifications-amount');
            var numNotifElNavbar    = $('.notifications-amount-navbar');
            var scoreMenuMobEl      = $('.go-notif-mobile');

            numNotifEl.text('');
            numNotifElNavbar.text('');

            scoreMenuMobEl.attr('data-notifications', '');

            if (counter > 0 && counter < 50) {


                numNotifEl.text(counter);
                numNotifElNavbar.text(counter);

                scoreMenuMobEl.attr('data-notifications', counter);
               
                // vm.setCookie('TRACK_NOTIFICATIONS', counter, 365242);

            } else if (counter > 49){

                numNotifEl.text('49+');
                numNotifElNavbar.text('49+');

                scoreMenuMobEl.attr('data-notifications', '49+');

                // vm.setCookie('TRACK_NOTIFICATIONS', '49+', 365242);

                $('.go-notif-mobile:before').css({
                    "width" : "22px",
                    "height" : "22px",
                    "line-height" : "22px",
                    "bottom" : "38px"
                });

            } else {

                numNotifEl.text('');
                numNotifElNavbar.text('');

                scoreMenuMobEl.attr('data-notifications', '');
                $('.go-notif-mobile:before').css('visibility', 'hidden');

                // vm.setCookie('TRACK_NOTIFICATIONS', '', 365242);

            }

        }

        vm.setUserScore = function(scoreObj){

            var scoreEl              = $('#gc-user-score');
            var scoreElClass         = $('.gc-user-score');

            var scoreMenuEl1        = $('#gc-user-score-menu1');
            var scoreMenuEl2        = $('#gc-user-score-menu2');

            var fractionStarsEl     = $('.fraction-stars');

            var scorePerc = numberToRoundedFloat(scoreObj.PERC_SATISFACAO);
            var score     = numberToRoundedFloat(scoreObj.MEDIA_AVL);

            scoreEl.text(scorePerc + '%');
            scoreElClass.text(scorePerc + '%');

            scoreMenuEl1.text(scorePerc + '%');
            scoreMenuEl2.text(scorePerc + '%');

            fractionStarsEl.attr('style', `--rating: ${score} !important;`);

        }

        vm.setCookie = function(cname, cvalue, exdays){
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        };

        vm.getCookie = function(cname) {
			var name = cname + "=";
			var decodedCookie = decodeURIComponent(document.cookie).trim();
			var ca = decodedCookie.split(';');
			for(var i = 0; i <ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) == 0) {
					return c.substring(name.length, c.length);
				}
			}
			return "";
		};

        vm.deleteCookie = function(cname) {
			var cookies = document.cookie.split(";");
			//cname = cname + "=";
			for (var i = 0; i < cookies.length; i++) {
				var cookie = cookies[i];
				var eqPos = cookie.indexOf("=");
				var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

				if( (name+'').trim() == cname){
					document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
				}
			}
		}

        vm.ngSetCookie = function(cname, cvalue, options = {}){

            try {
                
                $cookies.putObject(cname, cvalue, options);

            } catch (error) {
                
                console.log(error);

            }

        }

        vm.ngGetCookie = function(cname){

            try {

                return $cookies.getObject(cname);
                
            } catch (error) {

                console.log(error);
                
            }

        }

        vm.ngRemoveCookie = function(cname, options = {}){

            try {
                
                $cookies.remove(cname, options);

            } catch (error) {

                console.log(error);
                
            }

        }

        vm.sanitizeModuloArr = function(arr, prop, value, dupedKeys){

            var unique = removeDupesByKey(arr, dupedKeys);

            var filter = filterByPropValue(unique, prop, value);

            return filter;

        }

        vm.generateUniqueId = function () {
            return Date.now().toString(36) + Math.random().toString(36).substr(2);
        }

		vm.isInsideIFrame = function(){
            return !!document.location.ancestorOrigins.length;
		}

        vm.init = function () {
            
            var self            = this;

            var notificacoes    = $('#old_notificacoes').val();
            var user_score      = $('#user_score').val();

            vm.LISTA_NOTIFICACOES = JSON.parse(notificacoes);

            vm.NUM_NOTIFICACOES = vm.LISTA_NOTIFICACOES.length;

            vm.NOTA_AVALIACAO = JSON.parse(user_score);

            self.countNotifications(vm.NUM_NOTIFICACOES);

            self.setUserScore(vm.NOTA_AVALIACAO);

            vm.CriarElementos();

            vm.recontar_load_modules = 0;

            vm.CarregarModulos = function(){
                vm.recontar_load_modules = vm.recontar_load_modules + 1;

                if(vm.recontar_load_modules <= 10){
                    $timeout(function(){
                        var loaded_modulos = $('#loaded_modulos').val();

                        if(loaded_modulos == 1){
                            vm.recontar_load_modules = 11;

                            var modulos = $('#old_modulos').val();
                                modulos = JSON.parse(modulos);

                            for(var index = 0; index < modulos.length; index++) {
                                var moduloPai= modulos[index];
                                var mensagem = '';

                                for(var j = 0; j < moduloPai.ITENS.length; j++) {
                                    var modulinho  = moduloPai.ITENS[j];

                                    mensagem = mensagem +'<br>'+ '<div style="width: 100%; border: 1px solid;"><div style="display: inline-block; float: left;"><strong>'+modulinho.DESCRICAO+'</strong>:</div><div style="display: inline-block; float: right;">'+modulinho.PERIODO_EXPIRACAO+'</div></div>';
                                }

                                mensagem = mensagem + '<br>';

                                var notf = {
                                    "ID"                : trim_null(modulinho.NOTIFICACAO_ID) != '' ? modulinho.NOTIFICACAO_ID : 0,
                                    "TIPO"              : 5,
                                    "USUARIO_ID"        : 0,
                                    "TODOS"             : 0,
                                    "MENU_ID"           : 0,
                                    "TITULO"            : 'MÓDULOS FORA DO PERÍODO LIBERADO ('+moduloPai.ESTABELECIMENTO_DESCRICAO+')',
                                    "MENSAGEM"          : mensagem,
                                    "LEITURA"           : 0,
                                    "ENVIO"             : 0,
                                    "DATA_HORA"         : moment().format('DD.MM.YYYY HH:mm'),
                                    "DATA_HORA_FORMAT"  : moment().format('DD/MM/YYYY HH:mm'),
                                    "EMITENTE"          : 0,
                                    "AGENDAMENTO"       : moment().format('DD.MM.YYYY HH:mm'),
                                    "EXECUTADO"         : 0,
                                    "TABELA"            : 'TBCONFIGURACAO_PERIODO',
                                    "TABELA_ID"         : modulinho.ID,
                                    "VISUALIZADO"       : modulinho.VISUALIZADO,
                                    "MEDIA_AVL"         : 0,
                                    "PERC_SATISFACAO"   : 0
                                }

                                if(notf.VISUALIZADO == 0){

                                    var dupe = hasPropertyValue(vm.LISTA_NOTIFICACOES, 'ID', notf.ID);

                                    if(!dupe){
                                        vm.LISTA_NOTIFICACOES.push(notf);
                                    }
                                    
                                }
                               
                            }	

                            var dados = {
                                DADOS: vm.LISTA_NOTIFICACOES
                            };

                            if(dados.DADOS.length > 0){

                                $ajax.post('/_11190/api/gravarNotificacaoModulo', dados).then(function(response){

                                    var notificationsCount = removeDupesByKey(dados.DADOS, ['ID']).length;
    
                                    vm.countNotifications(notificationsCount);
                                    // vm.setUserScore(vm.LISTA_NOTIFICACOES[notificationsCount - 1]);
    
                                });

                            }

                            vm.deleteCookie('NOTIFICAR_MODULOS');
                        }else{
                            vm.CarregarModulos();
                        }

                    },1000);
                }
            }

            if(vm.getCookie('NOTIFICAR_MODULOS') != ''){
                vm.CarregarModulos();                
            }

            document.onreadystatechange = () => {
                if (document.readyState === 'complete') {
                    var abrir_notificacoes = Number(trim_null($('#_abrir_notificacoes').val()));

                    var iframe = vm.isInsideIFrame();

                    if(abrir_notificacoes == 1){
                        $('.alterarAbrirNotificacao').attr('checked', true);
                    }else{
                        $('.alterarAbrirNotificacao').attr('checked', false);
                    }

                    if(vm.NUM_NOTIFICACOES > 0 && abrir_notificacoes > 0 && !iframe){
                        toggleNotificationsPanelJS();
                    }
                }
            };
                
        }

        vm.getHtml = function () {

            var html = `<ul id="notifications-list">
                            <li class="notification-item" ng-repeat="item in vm.LISTA_NOTIFICACOES track by $index" id="NTF-{{ $index }}">
                                <div
                                    id="notification-alert"
                                    class="alert alert-secondary" role="alert" style="overflow-wrap: anywhere">
                                    <a role="button" id="close-notification" class="close" aria-label="Close" ng-click="vm.setViewedNotification(item, $index)" ng-if="item.TIPO != 5">
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                    </a>
                                    <a role="button" id="close-notification" class="close" aria-label="Close" ng-click="vm.setViewedModulo(item, $index)" ng-if="item.TIPO == 5">
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                    </a>
                                    <div id="alert-title" style="margin: .5rem 0; font-weight: 600; font-size: 16px; text-align: left;">{{ item.TITULO }}</div>
                                    <div ng-bind-html="trustAsHtml(item.MENSAGEM)" id="mensagem"></div>
                                    <div style="float: right; font-size: 7pt; margin: 2px -10px 0px 0px;">{{ item.DATA_HORA_FORMAT }}</div>
                                </div>
                            </li>
                        </ul>`;

            return html;

        }

        vm.setViewedNotification = function(item, index){

            ajaxSetViewedNotification(item, index);

        }

        vm.setViewedModulo = function(item, index){

            ajaxSetViewedNotification(item, index)

        }

        vm.setAllNotificationsViewed = function(id, e){

            ajaxSetAllNotificationsViewed(id);

        }

        vm.CriarElementos = function () {
            var that = this;

            var html = that.getHtml();
            var obj = $('#' + that.div_elemento_id + '');

            if (obj.length > 0) {

                var scope = obj.closest('.ng-scope').scope();
                obj.append(html);

                if (typeof gScope.childScopes == 'undefined') { gScope.childScopes = []; }

                if (that.key_object_scop >= 0) {
                    var childScope = gScope.childScopes[that.key_object_scop];

                    if (typeof childScope != 'undefined') {
                        childScope.$destroy();
                        gScope.childScopes[that.key_object_scop] = undefined;
                    }
                }

                if (that.key_object_scop < 0) {
                    that.key_object_scop = gScope.childScopes.length;
                }

                childScope = scope.$new();
                gScope.childScopes[that.key_object_scop] = childScope;

                that.compiledElement2 = $compile(obj.contents())(childScope);
                childScope.$on("$destroy", function () {
                    that.compiledElement2.remove();
                });

                that._elemento = $('#' + that.div_elemento_id + '');

            }
        }

        var metodos = [
            {
                METHOD: 'ALERTAENOTIFICACAO',
                FUNCTION: function (ret) {
                    $rootScope.$apply(function () {

                        vm.LISTA_NOTIFICACOES.push(JSON.parse(ret.MENSAGE.DADOS.MENSAGEM));

                        var notificationsCount = vm.LISTA_NOTIFICACOES.length;

                        vm.countNotifications(notificationsCount);

                        vm.setUserScore(vm.LISTA_NOTIFICACOES[notificationsCount - 1]);

                    });
                }
            },
            {
				METHOD  :'NOTIFICACAO',
				FUNCTION:function(ret){
					var mensagem = ret.MENSAGE.DADOS.MENSAGEM;
					var titulo   = ret.MENSAGE.DADOS.TITULO;
                    var agd_id   = ret.MENSAGE.DADOS.AGENDAMENTO_ID;
                    var tipo     = ret.MENSAGE.DADOS.TIPO;

					var notf = {
                        "ID"                : agd_id,
                        "TIPO"              : tipo,
                        "USUARIO_ID"        : 0,
                        "TODOS"             : 0,
                        "MENU_ID"           : 0,
                        "TITULO"            : titulo,
                        "MENSAGEM"          : mensagem,
                        "LEITURA"           : 0,
                        "ENVIO"             : 0,
                        "DATA_HORA"         : "",
                        "DATA_HORA_FORMAT"  : "",
                        "EMITENTE"          : 0,
                        "AGENDAMENTO"       : 0,
                        "EXECUTADO"         : 0,
                        "TABELA"            : 0,
                        "TABELA_ID"         : 0,
                        "VISUALIZADO"       : 0,
                        "MEDIA_AVL"         : 0,
                        "PERC_SATISFACAO"   : 0
                    }

                    $rootScope.$apply(function () {
                        var encontrado = false;

                        angular.forEach(vm.LISTA_NOTIFICACOES, function(item, key){
                            if(item.ID == notf.ID){
                                encontrado = true;
                            }
                        });

                        if(!encontrado){
                            vm.LISTA_NOTIFICACOES.push(notf);
                            var notificationsCount = vm.LISTA_NOTIFICACOES.length;

                            vm.countNotifications(notificationsCount);
                            vm.setUserScore(vm.LISTA_NOTIFICACOES[notificationsCount - 1]);
                        }
                    });

				}
			},
            {
				METHOD  :'NOTIFICACAO_V2',
				FUNCTION:function(ret){
					var mensagem = ret.MENSAGE.DADOS.MENSAGEM;
					var titulo   = ret.MENSAGE.DADOS.TITULO;
                    var agd_id   = ret.MENSAGE.DADOS.AGENDAMENTO_ID;
                    var tipo     = ret.MENSAGE.DADOS.TIPO;

					var notf = {
                        "ID"                : agd_id,
                        "TIPO"              : tipo,
                        "USUARIO_ID"        : 0,
                        "TODOS"             : 0,
                        "MENU_ID"           : 0,
                        "TITULO"            : titulo,
                        "MENSAGEM"          : mensagem,
                        "LEITURA"           : 0,
                        "ENVIO"             : 0,
                        "DATA_HORA"         : "",
                        "DATA_HORA_FORMAT"  : "",
                        "EMITENTE"          : 0,
                        "AGENDAMENTO"       : 0,
                        "EXECUTADO"         : 0,
                        "TABELA"            : 0,
                        "TABELA_ID"         : 0,
                        "VISUALIZADO"       : 0,
                        "MEDIA_AVL"         : 0,
                        "PERC_SATISFACAO"   : 0
                    }

                    $rootScope.$apply(function () {
                        var encontrado = false;

                        var msg = window.localStorage.getItem('MSG_CHAMADOS');
                        if(msg == null){
                            msg = [];
                        }else{
                            msg = JSON.parse(msg);
                        }

                        angular.forEach(msg, function(item, key){
                            if(item.ID == notf.ID){
                                encontrado = true;
                            }
                        });

                        if(!encontrado){
                            msg.push({ID: notf.ID, TIPO: notf.TIPO, TITULO: notf.TITULO});
                            window.localStorage.setItem('MSG_CHAMADOS',JSON.stringify(msg));

                            if(Notification.permission == 'granted'){

                                var url_logo = urlhost+'/assets/images/logo_notificacao.png';
                                let chamado  = new Notification(notf.TITULO, 
                                    {
                                        
                                        body: notf.MENSAGEM,//'Um novo chamado foi registrado na área !',
                                        icon: url_logo
                                    });
                                var chamadoId = notf.TITULO;
                                chamadoId     = chamadoId.replace(/\D/g,'')
                                chamado.onclick = function(event){
                                    event.preventDefault(); // prevent the browser from focusing the Notification's tab
                                    window.open(urlhost+"/_32050?CHAMADO_ID="+chamadoId, "_blank");
                                }
                            }

                            /*execAjax1('POST', '/_11190/api/setViewedNotification', { 'ID': notf.ID }, 
                                function(r){                        
                                },                        
                                function(e){                        
                                    showErro('Falha ao marcar notificação do chamado como visualizado');
                                    console.log(e);                        
                                }
                            );*/
                        }
                    });

				}
			},
        ];

        SocketWeb.addMetode(metodos);
    };

})(angular);

(function ($) {

    // $(document).on('click', '.btn-abrir-AlertasENotificacoes', function(event) {
    //     $('#AlertasENotificacoes').css('display','block');
    // }); 

    // $(document).on('click', '.btn-fechar-AlertasENotificacoes', function(event) {
    //     $('#AlertasENotificacoes').css('display','none');
    // });

    /*
    $(document).on('click', '.btn-agendar-notificacao', function(event) {
        
        var ds = {AGD_ID : id, AGD_TM : ag };

        execAjax1('POST', urlhost + '/_11190/agendamento',ds,
            function(data){
                
            }
        );

    });
    */

})(jQuery);